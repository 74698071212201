<template>
  <div>
    <div>
      <div class="h-90 position-relative">
        <vue-autosuggest
          :suggestions="suggestions"
          :limit="5"
          :input-props="input_locations"
          v-model="state_model"
          @input="inputLocation"
          class="w-100"
          :get-suggestion-value="getLocation"
        > 
          <template slot-scope="{suggestion}">
            <span class="my-suggestion-item">{{ suggestion.item.text }}</span>
          </template>

          <template slot="before-suggestions">
            <div class="m-1">
              {{$t("search.searchingBy")}}: {{state_model}}
            </div>
            <hr class="m-0">
          </template>
        </vue-autosuggest>
        <feather-icon 
          icon="XIcon" 
          class="x-icon-autosuggest-filter" 
          size="16" 
          v-if="state_model.length > 0"
          @click="resetState()"
        />

      </div>
    </div>
  </div>
</template>

<script>
import { VueAutosuggest } from 'vue-autosuggest';

export default {
  name: 'locationsStateSuggest',
  components: {
    VueAutosuggest,
  },
  props: {
    states: {
      type: Array,
      required: true,
    },
    state: {
      type: Object,
    }
  },
  data () {
    return {
      input_locations: {
        class: 'form-control',
        placeholder: this.$t("search.typeLocation")
      },
      suggestions: [],
      state_model: '',
    }
  },
  created() {
    if (Object.keys(this.state).length > 0) {
      this.state_model = this.state.text;
    }
  },
  methods: {
    inputLocation(text) {
      const states_suggest = this.states.filter((item) => (item.text.toLowerCase().indexOf(text.trim()) !== -1));
      this.suggestions = [{data: states_suggest}];
      if (states_suggest.length === 0) {
        this.suggestions = [{data: [{text: this.$t("search.noResults")}]}];
      }
    },
    getLocation(item) {
      this.$emit('location_selected', item.item);
    },
    resetState() {
      this.state_model = ' ';
      this.$emit('location_selected', {});
    }
  },
  watch: {
    '$i18n.locale'() {
      this.input_locations = {
        class: 'form-control',
        placeholder: this.$t("search.typeLocation")
      }
    },
  }
}
</script>
<style>
.autosuggest__results-container .autosuggest__results {
  background-color: white !important;
  margin-top: 0;
  border-radius: 0.5rem;
  box-shadow: 0 15px 30px 0 rgb(0 0 0 / 11%), 0 5px 15px 0 rgb(0 0 0 / 8%) !important;
  position: absolute !important;
  top: 0 !important;
  width: 100%;
  overflow-y: auto;
  max-height: 40vh;
}
.autosuggest__results-container .autosuggest__results ul li {
  padding: 0.7em 1em;
  list-style: none;
}
.autosuggest__results-container .autosuggest__results ul li:hover {
  background-color: #EDEDED;
}
.x-icon-autosuggest-filter {
  background-color: white;
  position: absolute; 
  right: 8px; 
  top: 13px;
}
</style>